import React, { useEffect } from "react";
import AppContext, { appConsts } from "../AppContext";
import DispatchContext from "../DispatchContext";
import LocationAutocomplete from "./LocationAutocomplete";
import TimeSelect from "./TimeSelect";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SeoHelmet } from "./SeoHelmet";

function LocationSelect() {
  const context = React.useContext(AppContext);
  const dispatch = React.useContext(DispatchContext);

  const navigate = useNavigate();

  const redirectToFireworks = function (tagid = null) {
    if (context.localityRaw && context.localityRaw.length > 0) {
      const splitLocality = context.localityRaw.split(", ");
      const path =
        splitLocality[1] + "/" + splitLocality[0].replaceAll(" ", "-");
      let uri =
        "/" +
        path.toLowerCase() +
        (tagid ? "?tag=" + tagid : "") +
        (context.days > 1 && !tagid ? "?days=" + context.days : "");

      // Reset Context
      dispatch({ type: "setLocalityRaw", value: null });
      dispatch({ type: "setTag", value: null });
      dispatch({ type: "setCurrentEvents", value: [] });
      dispatch({ type: "setLocality", value: null });
      dispatch({ type: "setLocationSelectError", value: false });
      dispatch({ type: "setLoadAllEvents", value: false });
      navigate(uri);
    } else {
      dispatch({
        type: "setLocationSelectError",
        value: "Please select a Location from the list.",
      });
    }
  };

  // Unset Raw Value on Load
  useEffect(() => {
    dispatch({ type: "setLocalityRaw", value: null });
  }, []);

  useEffect(() => {
    const reqController = new AbortController();

    if (context.locationPermission) {
      // Try to Obtain Location
      if (navigator.geolocation) {
        // With Coordiantes get the Locality
        // Use Browser Location
        navigator.geolocation.getCurrentPosition((position) => {
          dispatch({
            type: "setCoordinates",
            value: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
        });
        async function fetchLocation() {
          try {
            const urlPath =
              "locations?latitude=" +
              context.latitude +
              "&longitude=" +
              context.longitude;
            const locationPromise = await fetch(context.api.base + urlPath, {
              signal: reqController.signal,
            });
            const locations = await locationPromise.json();
            return locations;
          } catch {
            console.log("Our request was cancelled.");
          }
        }
        if (context.latitude && context.longitude) {
          fetchLocation().then((locations) =>
            locations.length > 0
              ? dispatch({
                  type: "setLocality",
                  value: locations[0],
                })
              : console.log("No locations found")
          );
        }
      } else {
        // Prompt Manual Location
        console.log("Browser does not support gelocation.");
      }
    }

    return () => {
      reqController.abort();
    };
  });

  return (
    <React.Fragment>
      {/* <PermitLocationButton locationPermission={context.locationPermission} /> */}
      <SeoHelmet />

      <div>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          columnSpacing={{ xs: 0, lg: 1 }}
        >
          <Grid xs={12} key="form-prompt">
            <Typography sx={{ fontSize: "2rem", fontWeight: "semibold" }}>
              Are there Fireworks in
            </Typography>
          </Grid>
          <Grid xs={12} md={8} key="form-location">
            <LocationAutocomplete />
          </Grid>
          <Grid xs={12} md={4} key="form-time">
            <TimeSelect />
          </Grid>
          {checkButtons(redirectToFireworks)}
        </Grid>
      </div>
    </React.Fragment>
  );
}

function checkButtons(redirectToFireworks) {
  const currentDate = new Date();
  const currentTheme =
    appConsts.site.seasonalThemes.find(
      (theme) => currentDate >= theme.startDate && currentDate <= theme.endDate
    ) || null;

  var buttons = [];
  buttons["check"] = (
    <Button
      variant="contained"
      sx={{
        width: "100%",
        fontSize: "1.25rem",
      }}
      size="large"
      onClick={() => {
        redirectToFireworks();
      }}
    >
      Check
    </Button>
  );

  if (currentTheme) {
    buttons[currentTheme.tag] = (
      <Button
        variant="contained"
        sx={{
          width: "100%",
          fontSize: "1.25rem",
        }}
        color="error"
        size="large"
        onClick={() => {
          redirectToFireworks(currentTheme.tag);
        }}
      >
        {currentTheme.buttonText}
      </Button>
    );
  }

  // create Grid and loop through buttons and append inside grid
  return Object.keys(buttons).map((button) => (
    <Grid
      key={button}
      xs={12}
      md={12 / Object.keys(buttons).length}
      sx={{ paddingX: { xs: 0, md: 0.25 } }}
    >
      {buttons[button]}
    </Grid>
  ));
}

export default LocationSelect;
